import { API_END_POINTS } from "../../../utils/api-endpoint";
import { APP_ROUTES } from "../../../utils/app-routes";
import {
  GENESIS_USER_EMAIL,
  GENESIS_USER_SIGNUP,
  GENESIS_USER_TOKEN,
  USER_DETAILS,
} from "../../../utils/constants";
import { saveState } from "../../../utils/storageUtil";
import { notify } from "../../../components/Toast/ToastComponent";
import { getData } from "src/hooks/crud/data.query";


export const routeToCorrectStep = async (res, navigate) => {
    const { isVerified, isSocialLogin, isPaymentVerified, isOnboarded } = res?.data || {};

    if (!isVerified && !isSocialLogin) {
      localStorage.setItem(USER_DETAILS, JSON.stringify(res));
      navigate(APP_ROUTES.otpVerification, { state: { email: res?.data?.emailAddress } });
    } else if (!isPaymentVerified) {
      localStorage.setItem(USER_DETAILS, JSON.stringify(res));
      localStorage.setItem(GENESIS_USER_TOKEN, res?.token);
      saveState(GENESIS_USER_SIGNUP, true);
      saveState(GENESIS_USER_EMAIL, res?.data?.emailAddress);
      navigate(APP_ROUTES.paymentPlan, {
        email: res?.data?.emailAddress,
        token: res?.token,
      });
      notify("Please complete your payment to continue", "success");
    } else if (!isOnboarded) {
      localStorage.setItem(USER_DETAILS, JSON.stringify(res));
      localStorage.setItem(GENESIS_USER_TOKEN, res?.token);
      saveState(GENESIS_USER_SIGNUP, true);
      saveState(GENESIS_USER_EMAIL, res?.data?.emailAddress);
      let savedPage = localStorage.getItem("onboardingPage");
      if (!savedPage) {
        const response = await getData(API_END_POINTS.onboarding);
        console.log("Onboarding progress response:", response);
        savedPage = response?.data?.progressPage;
      }
      if (savedPage !== undefined) {
        localStorage.setItem("onboardingPage", savedPage);
      }        
      if (!res?.data?.profileImageURL) {
        navigate(APP_ROUTES.uploadProfileImage);
      } else {
        navigate(APP_ROUTES.onboardingQuestion);
      }
    } else if ((isVerified || isSocialLogin) && isPaymentVerified && isOnboarded) {
      localStorage.setItem(USER_DETAILS, JSON.stringify(res));
      localStorage.setItem(GENESIS_USER_TOKEN, res?.token);
      saveState(GENESIS_USER_SIGNUP, false);
      saveState(GENESIS_USER_EMAIL, res?.data?.emailAddress);
      navigate(APP_ROUTES.root);
      notify(res?.message, "success");
    }
    else {
      notify("An unknown error occurred", "error");
      navigate(APP_ROUTES.root);
    }
  }