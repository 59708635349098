import moment from "moment";
import React, { useState } from "react";
import { useForm } from "react-hook-form";
import BoxReveal from "src/components/magicui/box-reveal";
import Chart from "../../components/Chart/LineChart";
import DashboardComponent from "../../components/Dashboard/DashboardEventComponent";
import { BallTriangleLoader } from "../../components/Loader/Loader";
import { MetricsCardComponent } from "../../components/Metrics/MetricsCard";
import FormSelect from "../../components/Select/FormSelect";
import { ChartDropDown } from "../../constants/constant";
import { useDataQuery } from "../../hooks/crud/data.query";
import { API_END_POINTS } from "../../utils/api-endpoint";

const Dashboard = () => {
  const [selectedChartValue, setSelectedChartValue] = useState("Week");
  const {
    handleSubmit,
    control,
    formState: { errors },
    register,
    reset,
  } = useForm({});
  const {
    data: userData,
    isFetching,
    isLoading,
  } = useDataQuery({
    url: API_END_POINTS.profile,
    params: {},
  });
  const { data: socialOverviewData } = useDataQuery({
    url: API_END_POINTS.socialOverview,
    params: {},
  });
  const { data: chartData } = useDataQuery({
    url: API_END_POINTS.charts,
    params: {
      startDate:
        selectedChartValue === "Monthly"
          ? moment().subtract(1, "month").format("YYYY-MM-DD")
          : selectedChartValue === "Yearly"
          ? moment().subtract(1, "year").format("YYYY-MM-DD")
          : moment().subtract(6, "days").format("YYYY-MM-DD"),
      endDate: moment().format("YYYY-MM-DD"),
    },
  });

  const { data: googleCalendarLinkedStatus } = useDataQuery({
    url: API_END_POINTS.googleCalendarLinkStatus,
    params: {},
  });

  return (
    <div>
      <span className="gray-400 regularL">
        Task lists, event vibes, and social beats
      </span>
      {isLoading ? (
        <div className="align-center justify-center">
          <BallTriangleLoader />
        </div>
      ) : (
        <BoxReveal width="100%" boxColor={"transparent"} duration={0.5}>
          <div className="flex flex-col lg:flex-row lg:justify-between">
            <div className="lg:w-8/12 w-9/12 mb-8 lg:mb-0">
              <DashboardComponent
                className="w-full"
                googleCalendarStatus={googleCalendarLinkedStatus?.data}
              />
            </div>
            <div className="lg:w-4/12 w-9/12 lg:pl-5">
              <span className="white">Your Platform</span>
              <div className="flex-column">
                {socialOverviewData?.data
                  ?.filter((filter) => filter?.isActive)
                  ?.map((item, index) => (
                    <MetricsCardComponent
                      isView
                      platformName={item.platformName}
                      audienceCount={item?.audienceCount}
                      isLinked={item?.isLinked}
                    />
                  ))}
              </div>
              <div className="card-second-wrapper">
                <div className="flex justify-between align-top">
                  <span className="white text-2xl">Your Progress</span>
                  <div className="w-50">
                    <FormSelect
                      control={control}
                      label="Status"
                      id="status"
                      options={ChartDropDown}
                      onChange={(e) => {
                        if (e) {
                          setSelectedChartValue(e.value);
                        } else {
                          setSelectedChartValue("");
                        }
                      }}
                      // value={"Low"}
                      name="interval"
                      placeholder={selectedChartValue}
                    />
                  </div>
                </div>
                {userData && chartData && (
                  <div className="mt-4">
                    <Chart chartData={chartData?.data} />
                  </div>
                )}
              </div>
            </div>
          </div>
        </BoxReveal>
      )}
    </div>
  );
};

export default Dashboard;
